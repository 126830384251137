<template>
  <main>
    <div v-if="errorBuilding" class="error">{{ errorBuilding }}</div>
    <transition name="fade">
      <div v-if="building" class="subnav-detail">
        <div class="mb-4 row">
          <div class="col-12 col-sm-6">
            <p class="mb-4">{{ building.aboutUsOwner }}</p>
            <p>{{ building.aboutUsSummary }}</p>
          </div>
          <div class="col-12 col-sm-6">
            <img class="w-75 rounded-3" :src="building.image2URLMedium" />
          </div>
        </div>
        <div class="mb-4 row">
          <div class="col-12 col-sm-6">
            <img class="w-75 rounded-3" :src="building.image1URLMedium" />
          </div>
          <div class="col-12 col-sm-6">
            <p>{{ building.aboutUsDetail }}</p>
          </div>
        </div>
      </div>
    </transition>
  </main>
</template>

<script>
import getBuildingByURL from "@/composables/getBuildingByURL";

export default {
  setup() {
    const { errorBuilding, building } = getBuildingByURL(window.location.host);

    return { errorBuilding, building };
  }
};
</script>

<style scoped>
.fade-enter-from {
  opacity: 0;
}
.fade-enter-active {
  transition: all 2s ease;
}
</style>